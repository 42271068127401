import React, { createContext, useState, useEffect, useCallback } from "react";
import { stringify } from "querystring";
import { useLocation, useHistory } from "react-router-dom";
import { lookupByUetr, lookupByPaymentDetails } from "../services/api";
import { IUetrResults, IAppContext, ISearchParams, IUetrResult, ESearchType } from "./Types";
import URLSearchParams from "../services/utils";

const parseSearchParams = (location: any) => {
  const query = new URLSearchParams(location.search);

  const searchType = query.get("searchType") === "paymentDetails" ? ESearchType.paymentDetails : ESearchType.uetr;
  const uetr = query.get("uetr") || "";
  const paymentref = query.get("paymentref") || "";
  const instructedAmount = parseFloat(query.get("instructedAmount") || "0") || 0;
  const date = query.get("date") || "";
  const currency = query.get("currency") || "";

  return { searchType, uetr, paymentref, instructedAmount, date, currency };
};

const DefaultResults: IUetrResults = {
  result: {},
  error: false,
  loading: false,
};

const DefaultSearchParams: ISearchParams = {
  searchType: ESearchType.uetr,
  uetr: "",
};

let AppContext = createContext<IAppContext>({
  searchParams: { ...DefaultSearchParams },
  results: { result: { uetr: "yyy", instructedAmount: 0 }, error: false, loading: false },
  changeSearchType: (type: string) => {},
  searchPayments: (params: any) => {},
  backToSearch: () => {},
});

function AppContextProvider(params: any) {
  let location = useLocation();
  let history = useHistory();
  const [searchParams, setSearchParams] = useState<ISearchParams>({ ...parseSearchParams(location) });
  const [results, setResults] = useState({ ...DefaultResults });

  const changeSearchType = (searchType: string) => {
    history.replace(
      `/search?${stringify({
        ...searchParams,
        searchType,
      })}`
    );

    setSearchParams({ searchType: searchType === "paymentDetails" ? ESearchType.paymentDetails : ESearchType.uetr, uetr: searchType === "paymentDetails" ? "" : searchParams?.uetr });
  };

  const backToSearch = () => {
    history.replace(`/search?${stringify({ searchType: searchParams.searchType, uetr: searchParams.uetr })}`);
  };

  const searchPayments = (params: any) => {
    history.push(
      `/results?${stringify({
        ...searchParams,
        ...params,
        searchType: searchParams.searchType,
      })}`
    );

    window.parent.postMessage(
      stringify({
        ...searchParams,
        ...params,
        searchType: searchParams.searchType,
      }),
      "https://corporates.db.com/solutions/client-access-solutions/international-payment-tracking"
    );
  };

  const _updateResults = useCallback((result: IUetrResult | null, loading: boolean, error?: boolean) => {
    setResults({ result: result, loading: loading, error: !!error });
  }, []);

  const lookup = useCallback(
    async (_searchParams: ISearchParams) => {
      const { searchType, uetr, paymentref, date, instructedAmount, currency } = _searchParams;
      try {
        if (searchType === "uetr") {
          _updateResults(null, true);
          const result = await lookupByUetr(uetr);

          if (!result || Object.keys(result).length === 0) {
            _updateResults(null, false, true);
          } else {
            _updateResults(
              {
                ...result,
                lastUpdate: result.lastUpdate ? new Date(result.lastUpdate) : null,
                valueDate: result.valueDate ? new Date(result.valueDate) : null,
                instructedAmount: result.instructedAmount ? parseFloat(result.instructedAmount.amount) : null,
                currency: result.instructedAmount ? result.instructedAmount.currency : null,
              },
              false
            );
          }
        } else {
          _updateResults(null, true);
          const result = await lookupByPaymentDetails(paymentref, date, instructedAmount, currency);
          if (!result || Object.keys(result).length === 0) {
            _updateResults(null, false, true);
          } else {
            _updateResults(
              {
                ...result,
                lastUpdate: result.lastUpdate ? new Date(result.lastUpdate) : null,
                valueDate: result.valueDate ? new Date(result.valueDate) : null,
                instructedAmount: result.instructedAmount ? parseFloat(result.instructedAmount.amount) : null,
                currency: result.instructedAmount ? result.instructedAmount.currency : null,
              },
              false
            );
          }
        }
      } catch (e) {
        _updateResults(null, false, true);
      }

      setSearchParams({ ..._searchParams });
    },
    [_updateResults]
  );

  useEffect(() => {
    if (location.pathname === "/results") {
      lookup(parseSearchParams(location));
    } else {
      setResults({ ...DefaultResults });
    }
  }, [location, lookup]);

  const defaultContext = {
    searchParams,
    results,
    lookup,
    changeSearchType,
    searchPayments,
    backToSearch,
  };

  return <AppContext.Provider value={defaultContext}>{params.children}</AppContext.Provider>;
}

export { AppContext, AppContextProvider };
