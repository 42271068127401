
export enum ESearchType {
    uetr = "uetr",
    paymentDetails = "paymentDetails"
};

export interface ISearchParams {
    searchType: ESearchType,
    uetr: string,
    instructedAmount?: number | any,
    paymentref?: string | any,
    currency?: string | any,
    date?: string | any
}

export interface IUetrResult {
    uetr?: string,
    instructedAmount?: number
    paymentref?: string
    currency?: string
    valueDate?: Date | null,
    orderingBIC?: string,
    beneficiaryBIC?: string,
    status?: string,
    lastUpdate?: Date | null
}

export interface IUetrResults {
    result: IUetrResult|null,
    loading: boolean,
    error: boolean
}

export interface IAppContext {
    searchParams: ISearchParams
    results: IUetrResults,
    changeSearchType: any,
    searchPayments: any,
    backToSearch: any
}