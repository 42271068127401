import React, { useContext, useState } from "react";
import { AppContext } from "../../context/AppContext";
import Input, { InputTypes } from "../../components/input";
import { Button, ButtonSizes } from "../../components/button";
import { Tabs, TabItem, TabContent } from "../../components/tab";
import { useTranslation } from "react-i18next";
import "./index.scss";
import { getCurrencies } from "../../services/api";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
//Payment Creation Date
function Search() {
  type DropdownSelectDataProps = {
    name: string;
    value: string;
  };

  type FormErrorsProps = {
    [key: string]: boolean;
  };

  const { t } = useTranslation();
  const { changeSearchType, searchPayments, searchParams } = useContext(AppContext);

  let currentTab = searchParams.searchType === "paymentDetails" ? 1 : 0;
  const [value, setValue] = useState(currentTab);
  const [formData, updateFormData] = useState<any>({});
  const [isInfoTooltipOpen, setInfoTooltipOpen] = useState(false);

  let initialFormErrors: FormErrorsProps = {};

  const [formErrors, updateFormErrors] = useState(initialFormErrors);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number, slug: string) => {
    setValue(newValue);
    changeSearchType(slug);
    updateFormErrors({
      ...initialFormErrors,
    });
  };

  const openInfoTooltip = () => {
    setInfoTooltipOpen(!isInfoTooltipOpen);
    setTimeout(() => {
      setInfoTooltipOpen(false);
    }, 1500);
  };

  const handleDateChange = (params: any) => {
    let inputFormErrors: FormErrorsProps = formErrors ? formErrors : {};
    let inputName = params.name;

    try {
      let inputValue = `${params.value.getFullYear()}-${params.value.getMonth() + 1}-${params.value.getDate()}`;
      let inputError = inputFormErrors[inputName];

      if (!inputValue) {
        inputError = true;
        updateFormErrors({
          ...inputFormErrors,
          [inputName]: inputError,
        });
      }

      if (inputValue) {
        inputFormErrors[inputName] = false;
        if (inputValue.indexOf("_") > -1 && inputName === "date") {
          inputFormErrors[inputName] = true;
        }

        const MIN_DATE_MS = 120 * 24 * 60 * 60 * 1000;
        const selectedDate = params.value.getTime();

        if (inputName === "date" && selectedDate < Date.now() - MIN_DATE_MS) {
          inputFormErrors[inputName] = true;
        }

        if (!inputValue.match(/^([0-9]+)(\.([0-9]+?)([0-9]+?))*?$/i) && inputName === "instructedAmount") {
          inputFormErrors[inputName] = true;
        }

        if (!inputValue.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i) && inputName === "uetr") {
          inputFormErrors[inputName] = true;
        }

        updateFormErrors({
          ...inputFormErrors,
        });

        if (!inputFormErrors[inputName]) {
          updateFormData({
            ...formData,
            [params.name]: inputValue,
          });
        }
      }
    } catch (e) {
      inputFormErrors[inputName] = true;
      updateFormErrors({
        ...inputFormErrors,
      });
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    let inputFormErrors: FormErrorsProps = formErrors ? formErrors : {};

    let inputValue = e.target.value;
    let inputName = e.target.name;
    let inputError = inputFormErrors[inputName];

    if (!inputValue) {
      inputError = true;
      updateFormErrors({
        ...inputFormErrors,
        [inputName]: inputError,
      });
    }

    if (inputValue) {
      inputFormErrors[inputName] = false;
      if (inputValue.indexOf("_") > -1 && inputName === "date") {
        inputFormErrors[inputName] = true;
      }

      if (!inputValue.match(/^([0-9]+)(\.([0-9]+?)([0-9]+?))*?$/i) && inputName === "instructedAmount") {
        inputFormErrors[inputName] = true;
      }

      if (!inputValue.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i) && inputName === "uetr") {
        inputFormErrors[inputName] = true;
      }

      updateFormErrors({
        ...inputFormErrors,
      });
    }
  };

  const handleDropdownChange = (value: string) => {
    updateFormData({
      ...formData,
      currency: value,
    });

    let inputFormErrors: FormErrorsProps = formErrors ? formErrors : {};

    let inputValue = value;
    let inputName = "currency";
    let inputError = inputFormErrors[inputName];

    if (!inputValue) {
      inputError = true;
      updateFormErrors({
        ...inputFormErrors,
        [inputName]: inputError,
      });
    }
    if (inputValue) {
      inputFormErrors[inputName] = false;
      updateFormErrors({
        ...inputFormErrors,
      });
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    let submitFormErrors: FormErrorsProps = {};
    if (searchParams.searchType === "paymentDetails") {
      submitFormErrors = {
        currency: true,
        instructedAmount: true,
        date: true,
        paymentref: true,
        ...formErrors,
      };
    } else {
      submitFormErrors = {
        uetr: true,
        ...formErrors,
      };
    }

    const _isFormValid = Object.keys(submitFormErrors).reduce((acc: boolean, it: string) => {
      if (submitFormErrors[it]) {
        acc = false;
      }

      return acc;
    }, true);

    if (_isFormValid) {
      searchPayments(formData);
    } else {
      updateFormErrors({
        ...submitFormErrors,
      });
    }

    event.preventDefault();
  };

  return (
    <div className={"search-page"}>
      <div className={"search-form-wrapper"}>
        <h3>{t("Payment Status Search")}</h3>
        <h4>{t("Please enter your UETR or alternatively your payment details")}</h4>
        <h6>{t("All fields are mandatory")}</h6>

        <Tabs value={value} onChange={handleTabChange}>
          <TabItem slug={"uetr"} value={0} isCurrent={true}>
            {t("UETR")}
          </TabItem>
          <TabItem slug={"paymentDetails"} value={1}>
            {t("Payment Details")}
          </TabItem>
        </Tabs>

        <div className={"tabs-content"}>
          <TabContent value={value} index={0}>
            <form onSubmit={handleSubmit}>
              <div className='form-element'>
                <Input
                  hasError={formErrors.uetr ? true : false}
                  message={t("Please enter UETR")}
                  onChange={handleInputChange}
                  name={"uetr"}
                  label={t(`Unique End-to-End Transaction Reference`)}
                  type={InputTypes.text}
                />
              </div>
              <Button size={ButtonSizes.Large}>{t("Search")}</Button>
            </form>
          </TabContent>
          <TabContent value={value} index={1}>
            <form onSubmit={handleSubmit}>
              <div className='form-elements-wrapper'>
                <div className='form-element'>
                  <Input
                    hasError={formErrors.paymentref ? true : false}
                    message={t("Please enter Payment Reference")}
                    onChange={handleInputChange}
                    name={"paymentref"}
                    label={t(`Payment Reference`)}
                    type={InputTypes.text}
                  />
                  <div className='tooltip-info-wrapper' onTouchStart={openInfoTooltip}>
                    <div className='tooltip-copy' style={{ display: isInfoTooltipOpen ? "block" : "" }}>
                      {t("Please enter the transaction reference provided on the original instruction")}
                    </div>
                  </div>
                </div>
                <div className='form-element'>
                  <Input
                    hasError={formErrors.date ? true : false}
                    message={t("Please enter a date in the last 3 months")}
                    onChange={handleDateChange}
                    name={"date"}
                    value={formData["date"]}
                    label={t(`Payment Creation Date`)}
                    type={InputTypes.date}
                  />
                </div>
                <div className='form-element'>
                  <Input
                    hasError={formErrors.instructedAmount ? true : false}
                    message={t("Please enter amount")}
                    onChange={handleInputChange}
                    name={"instructedAmount"}
                    label={t(`Instructed Amount`)}
                    type={InputTypes.number}
                    step={"0.01"}
                  />
                </div>
                <div className='form-element'>
                  <div className={"dropdown-wrapper"}>
                    <label>{t(`Currency`)}</label>
                    <div>
                      <Autocomplete
                        multiple
                        limitTags={1}
                        className={formErrors.currency ? "error" : ""}
                        options={getCurrencies().map((item: any) => ({
                          ...item,
                          title: `${item.value} - ${item.title}`,
                        }))}
                        onChange={(evt: any, value: any) => {
                          handleDropdownChange(value?.[0]?.value);
                        }}
                        getOptionLabel={(option: any) => option.title}
                        renderInput={(params) => <TextField {...params} variant='outlined' />}
                        getOptionSelected={(option, value) => option.value === value.value}
                        renderTags={(value: any) => {
                          return <span style={{ marginLeft: 6 }}>{value[0].value}</span>;
                        }}
                      />
                    </div>
                    <div>
                      {formErrors.currency && (
                        <div className={"message-wrapper"}>
                          <span className={"error-msg"}>{t("Please select currency")}</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <Button size={ButtonSizes.Large}>{t("Search")}</Button>
            </form>
          </TabContent>
        </div>
      </div>
    </div>
  );
}

export default Search;
