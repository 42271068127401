import React from 'react';
import {
  HashRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Search from './pages/search';
import Results from './pages/results';
import { AppContextProvider } from './context/AppContext';
import './App.scss';

function App() {
  return (
      <Router>
        <AppContextProvider>
          <Switch>
            <Route path="/search" component={Search}>
            </Route>
            <Route path="/results" component={Results}>
            </Route>
            <Route path="/" component={Search}>
            </Route>
          </Switch>
        </AppContextProvider>
      </Router>
  );
}

export default App;
