import React from 'react';

type TabContentProps = {
    children?: React.ReactNode;
    index: number;
    value: number;
};

const TabContent: React.FC<TabContentProps> = ({
    children,
    value,
    index,
}: TabContentProps) => {
    if (value !== index) {
        return null;
    }

    return <div>{children}</div>;
};

export default TabContent;