import React from "react";
import "./index.scss";

export enum ButtonSizes {
  Small,
  Medium,
  Large,
}

export enum ButtonColors {
  White,
  Blue,
}

type ButtonProps = {
  children?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  size?: ButtonSizes;
  variant?: ButtonColors;
  type?: 'button' | 'submit' | 'reset';
  tooltip?: string;
  isTooltipOpen?: boolean;
} & React.ComponentPropsWithoutRef<"button">;

const buttonClass = (size?: ButtonSizes, variant?: ButtonColors) => {
  let className = '';
  switch (size) {
    case ButtonSizes.Small:
      className = className.concat(' small');
      break;

    case ButtonSizes.Large:
      className = className.concat(' large');
      break;
    case ButtonSizes.Medium:
    default:
      className = className.concat(' medium');
      break;
  }
  switch (variant) {
    case ButtonColors.White:
      className = className.concat(' white');
      break;

    case ButtonColors.Blue:
    default:
      className = className.concat(' blue');
      break;
  }
  return className;
};

export const Button: React.FC<ButtonProps> = ({
  children,
  size,
  variant,
  type = 'button',
  tooltip,
  isTooltipOpen,
  ...props
}: ButtonProps) => {
  return (
    <div className="button-wrap">
      <button className={buttonClass(size, variant)} {...props}>
        {children}
      </button>
      {tooltip && isTooltipOpen &&
        <div className={'clipboard-copy-wrapper'}>
          <span className={'rectangle-hook'}></span>
          <div className={'clipboard-copy'}>{tooltip}</div>
        </div>}
    </div>
  );
};

export default Button;
