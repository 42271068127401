import React, { useContext, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { Button, ButtonColors, ButtonSizes } from "../../components/button";
import { useTranslation } from "react-i18next";
import { useClipboard } from "use-clipboard-copy";
import { useLocation } from "react-router";
import "./index.scss";

const STATUS_MAPPINGS = {
  ACCC: {
    text: "Payment credited to beneficiary",
    className: "accc",
  },
  RJCT: { text: "Payment has been rejected", className: "rjct" },
  "ACSP/G000": { text: "Payment in progress", className: "progress" },
  "ACSP/G001": { text: "Payment in progress", className: "progress" },
  "ACSP/G002": { text: "Payment in progress", className: "progress" },
  "ACSP/G003": { text: "Payment in progress", className: "progress" },
  "ACSP/G004": { text: "Payment in progress", className: "progress" },
};

function Results() {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const clipboard = useClipboard();
  let location = useLocation();
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const {
    results: { result = {}, loading },
    searchParams,
    backToSearch,
  } = useContext(AppContext);

  const showSearchDetails = () => {
    setOpen(!isOpen);
  };

  const getStatusClassname = (status: any) => {
    if (status) {
      switch (status) {
        case "ACCC":
          return STATUS_MAPPINGS["ACCC"]?.className;
        case "RJCT":
          return STATUS_MAPPINGS["RJCT"]?.className;
        default:
          return STATUS_MAPPINGS["ACSP/G000"]?.className;
      }
    }

    return STATUS_MAPPINGS["RJCT"]?.className;
  };

  const getMappedStatusMessage = (status: any) => {
    if (status) {
      switch (status) {
        case "ACCC":
          return STATUS_MAPPINGS["ACCC"]?.text;
        case "RJCT":
          return STATUS_MAPPINGS["RJCT"]?.text;
        default:
          return STATUS_MAPPINGS["ACSP/G000"]?.text;
      }
    }

    return "Not found";
  };

  const copyToClipboard = () => {
    const mainHost = "https://corporates.db.com/solutions/client-access-solutions/international-payment-tracking";
    let url = `${mainHost}#${location.search.replace("?", "")}`;

    clipboard.copy(url);
    setIsTooltipOpen(!isTooltipOpen);
    setTimeout(() => {
      setIsTooltipOpen(false);
    }, 1500);
  };

  let valueDate = result?.valueDate?.toISOString()?.split("T")[0];

  return (
    <main className='results'>
      <div className='wrapper'>
        <div className='inner'>
          <h2 className='main-title'>{t("Payment Status")}</h2>
          <p>{loading ? "Loading..." : ""}</p>
        </div>
        {!loading && (
          <div>
            <section className='search-cnt'>
              <div className='inner'>
                <div className='header-section'>
                  <h3 className={isOpen ? "section-title active" : "section-title"} onClick={showSearchDetails}>
                    {t("Search Details")}
                  </h3>
                  <Button
                    variant={ButtonColors.White}
                    size={ButtonSizes.Small}
                    onClick={() => {
                      backToSearch();
                    }}
                  >
                    {t("New Search")}
                  </Button>
                </div>
                <div className={isOpen ? "toggle js-show" : "toggle js-close"}>
                  <ul className='search-details flex-default'>
                    <li className='details-entry'>
                      <div className='entry-wrapper'>
                        <h5>{t("UETR")}</h5>
                        <p>{searchParams?.uetr || "-"}</p>
                      </div>
                    </li>
                    {searchParams?.searchType !== "uetr" && (
                      <li className='details-entry'>
                        <div className='entry-wrapper'>
                          <h5>{t("Payment Reference")}</h5>
                          <p>{searchParams?.paymentref || "-"}</p>
                        </div>
                      </li>
                    )}
                  </ul>
                  <ul className='search-details flex-default'>
                    <li className='details-entry'>
                      <div className='entry-wrapper'>
                        <h5>{t("Instructed Amount")}</h5>
                        <p>{searchParams?.instructedAmount?.toFixed(2) || "-"}</p>
                      </div>
                    </li>
                    <li className='details-entry'>
                      <div className='entry-wrapper'>
                        <h5>{t("Currency")}</h5>
                        <p>{searchParams?.currency || "-"}</p>
                      </div>
                    </li>
                    <li className='details-entry'>
                      <div className='entry-wrapper'>
                        <h5>{t("Date")}</h5>
                        <time dateTime=''>
                          {searchParams?.date
                            ? searchParams?.date
                                ?.split("-")
                                ?.reverse()
                                ?.map((s: string) => s.padStart(2, "0"))
                                ?.join("-")
                            : "-"}
                        </time>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </section>
            <section className='history-cnt'>
              <div className='inner'>
                <div className='header-section'>{result !== null ? <h3 className='section-title'>{t("Transaction History")}</h3> : <h3 className='section-title not-found'>{t("Error")}</h3>}</div>
                {result === null ? (
                  <div className='not-found'>
                    <span className='error-icon'></span>
                    <span>
                      {t("No results have been found")}. {t("Please try a")}{" "}
                      <a
                        href='/search'
                        onClick={(e) => {
                          e.preventDefault();
                          backToSearch();
                        }}
                      >
                        {t("New Search")}
                      </a>
                    </span>
                  </div>
                ) : (
                  <ul className='transaction-details flex-default'>
                    <li className='details-entry'>
                      <div className='entry-wrapper'>
                        <h5>{t("Status")}</h5>
                        <p className={getStatusClassname(result?.status)}>{t(getMappedStatusMessage(result?.status))}</p>
                      </div>
                    </li>
                    <li className='details-entry'>
                      <div className='entry-wrapper'>
                        <h5>{t("Share Results")}</h5>
                        {clipboard.isSupported() ? (
                          <Button variant={ButtonColors.Blue} size={ButtonSizes.Small} isTooltipOpen={isTooltipOpen} tooltip={t("Copied to clipboard")} onClick={copyToClipboard}>
                            {t("Copy URL")}
                          </Button>
                        ) : (
                          <div className='copy-not-supported'>{t("Copy URL manually")}</div>
                        )}
                      </div>
                    </li>
                    <li className='details-entry'>
                      <div className='entry-wrapper'>
                        <h5>{t("Currency")}</h5>
                        <p>{result?.currency}</p>
                      </div>
                    </li>
                    <li className='details-entry'>
                      <div className='entry-wrapper'>
                        <h5>{t("Instructed Amount")}</h5>
                        <p>{result?.instructedAmount ? result?.instructedAmount?.toFixed(2) : ""}</p>
                      </div>
                    </li>
                    <li className='details-entry'></li>
                    <li className='details-entry'>
                      <div className='entry-wrapper'>
                        <h5>{t("Value Date")}</h5>
                        {result?.valueDate ? (
                          <time dateTime=''>{`${
                            valueDate
                              ? valueDate
                                  ?.split("-")
                                  ?.reverse()
                                  ?.map((s: string) => s.padStart(2, "0"))
                                  ?.join("-")
                              : "-"
                          }`}</time>
                        ) : (
                          <span>{"-"}</span>
                        )}
                      </div>
                    </li>
                    <li className='details-entry'>
                      <div className='entry-wrapper'>
                        <h5>{t("Last Update")} (UTC)</h5>
                        {result?.lastUpdate ? (
                          <time dateTime=''>
                            {`${result?.lastUpdate?.toISOString()?.split("T")[0]?.split("-")?.reverse()?.join("-")};`}
                            <span>{result?.lastUpdate?.toLocaleTimeString().slice(0, result?.lastUpdate?.toLocaleTimeString().lastIndexOf(":"))}</span>
                          </time>
                        ) : (
                          <span>{"-"}</span>
                        )}
                      </div>
                    </li>
                    <li className='details-entry'></li>
                  </ul>
                )}
              </div>
            </section>
          </div>
        )}
      </div>
    </main>
  );
}

export default Results;
