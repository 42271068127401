import React from 'react';

import { Button, ButtonColors, ButtonSizes } from '../../components/button';

type TabItemProps = {
	onClick?: (event: React.SyntheticEvent) => void;
	onChange?: (event: React.SyntheticEvent, value: number, slug?: string) => void;
	isCurrent?: boolean;
	value: number;
	slug?: string;
	children: React.ReactNode;
};

const TabItem: React.FC<TabItemProps> = ({
	value,
	onClick,
	onChange,
	isCurrent,
	children,
	slug,
	...props
}: TabItemProps) => {
	const handleClick = (event: React.SyntheticEvent<Element, Event>) => {
		if (onChange) {
			onChange(event, value, slug);
		}

		if (onClick) {
			onClick(event);
		}
	};
	return (

		<Button
			disabled={isCurrent ? true : false}
			size={ButtonSizes.Medium}
			onClick={handleClick}
			variant={isCurrent ? ButtonColors.Blue : ButtonColors.White}
			{...props}
		>
			{children}
		</Button>

	);
};

export default TabItem;
