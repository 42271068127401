// @ts-nocheck
const URLSearchParams = window.URLSearchParams || function (searchString) {
    var self = this;
    self.searchString = searchString;
    self.get = function (name) {
        var results = new RegExp('[?&]' + name + '=([^&#]*)').exec(self.searchString);
        if (results == null) {
            return null;
        }
        else {
            return decodeURI(results[1]) || 0;
        }
    };
}

export default URLSearchParams;
