import React, { useCallback } from "react";
// @ts-ignore

import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import "./index.scss";

export enum InputTypes {
  text = "text",
  email = "email",
  number = "number",
  password = "password",
  date = "date",
  search = "search",
  time = "time",
  datetime = "datetime-local",
}

export enum InputSizes {
  Small,
  Medium,
  Large,
}

type TextInputProps = {
  size?: InputSizes;
  id?: string;
  type?: InputTypes;
  placeholder?: string;
  name?: string;
  disabled?: boolean;
  value?: string;
  autoFocus?: boolean;
  required?: boolean;
  label?: string;
  hasError?: boolean;
  readOnly?: boolean;
  message?: string | null;
  step?: string | null;
  onChange?: (event: React.ChangeEvent<HTMLInputElement> | any) => void;
};

const inputClass = (size?: InputSizes, hasError?: boolean) => {
  let className = hasError ? "error" : "";
  switch (size) {
    case InputSizes.Small:
      className = className.concat(" small");
      break;

    case InputSizes.Large:
      className = className.concat(" large");
      break;
    case InputSizes.Medium:
    default:
      className = className.concat(" medium");
      break;
  }
  return className;
};

let lastId = 0;

const MIN_DATE_MS = 120 * 24 * 60 * 60 * 1000;

const Input = ({ size, hasError, message, name, type, label, disabled, required, placeholder, value, step, onChange, readOnly = false }: TextInputProps) => {
  const inputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event);
    }
  };

  const memoizedInputChangeCallback = useCallback(inputChange, [onChange]);
  lastId += 1;
  return (
    <div className={"input-wrapper"}>
      {label && <label htmlFor={`${name || "id"}-${lastId}`}>{label}</label>}

      {type === InputTypes.date && (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            autoOk={true}
            variant='inline'
            inputVariant='outlined'
            className={inputClass(size, hasError)}
            format='dd-MM-yyyy'
            minDate={new Date(Date.now() - MIN_DATE_MS)}
            disableFuture={true}
            value={value || null}
            disabled={false}
            disableToolbar={true}
            onChange={(date) => {
              if (onChange) onChange({ name, value: date });
            }}
          />
        </MuiPickersUtilsProvider>
      )}
      {type !== InputTypes.date && (
        <input
          type={type}
          step={step || ""}
          className={inputClass(size, hasError)}
          id={`${name || "id"}-${lastId}`}
          value={value}
          name={name}
          disabled={disabled}
          placeholder={placeholder}
          onChange={memoizedInputChangeCallback}
          readOnly={readOnly}
        ></input>
      )}
      {message && hasError && (
        <div className={"message-wrapper"}>
          <span className={"error-msg"}>{message}</span>
        </div>
      )}
    </div>
  );
};

export default Input;
