const PROD_URL = 'https://corporateapi.db.com/payments/data/gpi/v1/';

export const lookupByUetr = async (uetr: string): Promise<any> => {
    const result = await fetch(`${PROD_URL}gpiTransactionInfo?uetr=${uetr}`);

    if (result.status === 200) {
        return result.json();
    }

    return null;
};

export const lookupByPaymentDetails = async (paymentref: string, date: string, instructedAmount: number, currency: string): Promise<any> => {
    console.log(date);
    const reformattedDate = date.split('-').map((s: string, i: number) => i > 0 ? s.padStart(2, '0') : s).join('-');
    console.log(reformattedDate);
    const result = await fetch(`${PROD_URL}gpiTransactionInfo?amount=${instructedAmount}&currency=${currency}&paymentDate=${reformattedDate}&senderReference=${paymentref}`);

    if (result.status === 200) {
        return result.json();
    }

    return null;
};

export const getCurrencies = () => {
    return [{
        "value": "EUR",
        "title": "Euro"
    }, {
        "value": "GBP",
        "title": "Pound Sterling"
    }, {
        "value": "USD",
        "title": "United States Dollar"
    }, {
        "value": "HKD",
        "title": "Hong Kong Dollar"
    }, {
        "value": "SGD",
        "title": "Singapore Dollar"
    },  {
        "value": "AED",
        "title": "United Arab Emirates Dirham"
    }, {
        "value": "AMD",
        "title": "Armenian Dram"
    }, {
        "value": "AOA",
        "title": "Angolan Kwanza"
    }, {
        "value": "ARS",
        "title": "Argentine Peso"
    }, {
        "value": "AUD",
        "title": "Australian Dollar"
    }, {
        "value": "AZN",
        "title": "Azerbaijani Manat"
    }, {
        "value": "BAM",
        "title": "Bosnia and Herzegovina Convertible Mark"
    }, {
        "value": "BBD",
        "title": "Barbados Dollar"
    }, {
        "value": "BDT",
        "title": "Bangladeshi Taka"
    }, {
        "value": "BGN",
        "title": "Bulgarian Lev"
    }, {
        "value": "BIF",
        "title": "Burundian Franc"
    }, {
        "value": "BMD",
        "title": "Bermudian Dollar"
    }, {
        "value": "BND",
        "title": "Brunei Dollar"
    }, {
        "value": "BRL",
        "title": "Brazilian Real"
    }, {
        "value": "BSD",
        "title": "Bahamian Dollar"
    }, {
        "value": "BYN",
        "title": "Belarusian Ruble"
    }, {
        "value": "CAD",
        "title": "Canadian Dollar"
    }, {
        "value": "CHF",
        "title": "Swiss Franc"
    }, {
        "value": "CLP",
        "title": "Chilean Peso"
    }, {
        "value": "CNY",
        "title": "Chinese Yuan"
    }, {
        "value": "COP",
        "title": "Colombian Peso"
    }, {
        "value": "CRC",
        "title": "Costa Rican Colon"
    }, {
        "value": "CVE",
        "title": "Cape Verdean Escudo"
    }, {
        "value": "CZK",
        "title": "Czech Koruna"
    }, {
        "value": "DKK",
        "title": "Danish Krone"
    }, {
        "value": "DOP",
        "title": "Dominican Peso"
    }, {
        "value": "DZD",
        "title": "Algerian Dinar"
    }, {
        "value": "EGP",
        "title": "Egyptian Pound"
    }, {
        "value": "FJD",
        "title": "Fiji Dollar"
    }, {
        "value": "GHS",
        "title": "Ghanaian Cedi"
    }, {
        "value": "GMD",
        "title": "Gambian Dalasi"
    }, {
        "value": "GNF",
        "title": "Guinean Franc"
    }, {
        "value": "GYD",
        "title": "Guyanese Dollar"
    }, {
        "value": "HRK",
        "title": "Croatian Kuna"
    }, {
        "value": "HUF",
        "title": "Hungarian Forint"
    }, {
        "value": "IDR",
        "title": "Indonesian Rupiah"
    }, {
        "value": "ILS",
        "title": "Israeli New Shekel"
    }, {
        "value": "INR",
        "title": "Indian Rupee"
    }, {
        "value": "ISK",
        "title": "Icelandic Króna"
    }, {
        "value": "JMD",
        "title": "Jamaican Dollar"
    }, {
        "value": "JOD",
        "title": "Jordanian Dinar"
    }, {
        "value": "JPY",
        "title": "Japanese Yen"
    }, {
        "value": "KES",
        "title": "Kenyan Shilling"
    }, {
        "value": "KRW",
        "title": "South Korean Won"
    }, {
        "value": "KWD",
        "title": "Kuwaiti Dinar"
    }, {
        "value": "LAK",
        "title": "Lao Kip"
    }, {
        "value": "LKR",
        "title": "Sri Lankan Rupee"
    }, {
        "value": "LSL",
        "title": "Lesotho Loti"
    }, {
        "value": "MAD",
        "title": "Moroccan Dirham"
    }, {
        "value": "MAG",
        "title": "Malagasy Ariary"
    }, {
        "value": "MOP",
        "title": "Macanese Pataca"
    }, {
        "value": "MRU",
        "title": "Mauritian Rupee"
    }, {
        "value": "MWK",
        "title": "Malawian Kwacha"
    }, {
        "value": "MXN",
        "title": "Mexican Peso"
    }, {
        "value": "MYR",
        "title": "Malaysian Ringgit"
    }, {
        "value": "MZN",
        "title": "Mozambican Metical"
    }, {
        "value": "NAD",
        "title": "Namibian Dollar"
    }, {
        "value": "NGN",
        "title": "Nigerian Naira"
    }, {
        "value": "NOK",
        "title": "Norwegian Krone"
    }, {
        "value": "NPR",
        "title": "Nepalese Rupee"
    }, {
        "value": "NZD",
        "title": "New Zealand Dollar"
    }, {
        "value": "OMR",
        "title": "Omani Rial"
    }, {
        "value": "PAB",
        "title": "Panamanian Balboa"
    }, {
        "value": "PEN",
        "title": "Peruvian Sol"
    }, {
        "value": "PHP",
        "title": "Philippine Peso"
    }, {
        "value": "PKR",
        "title": "Pakistani Rupee"
    }, {
        "value": "PLN",
        "title": "Polish Złoty"
    }, {
        "value": "QAR",
        "title": "Qatari Riyal"
    }, {
        "value": "RON",
        "title": "Romanian Leu"
    }, {
        "value": "RSD",
        "title": "Serbian Dinar"
    }, {
        "value": "RUB",
        "title": "Russian Ruble"
    }, {
        "value": "RWF",
        "title": "Rwandan Franc"
    }, {
        "value": "SAR",
        "title": "Saudi Riyal"
    }, {
        "value": "SCR",
        "title": "Seychelles Rupee"
    }, {
        "value": "SEK",
        "title": "Swedish Krona"
    }, {
        "value": "SLL",
        "title": "Sierra Leonean Leone"
    }, {
        "value": "SZL",
        "title": "Swazi Lilangeni"
    }, {
        "value": "THB",
        "title": "Thai Baht"
    }, {
        "value": "TND",
        "title": "Tunisian Dinar"
    }, {
        "value": "TRY",
        "title": "Turkish Lira"
    }, {
        "value": "TWD",
        "title": "New Taiwan Dollar"
    }, {
        "value": "TZS",
        "title": "Tanzanian Shilling"
    }, {
        "value": "UAH",
        "title": "Ukrainian Hryvnia"
    }, {
        "value": "UGX",
        "title": "Ugandan Shilling"
    }, {
        "value": "UYU",
        "title": "Uruguayan Peso"
    }, {
        "value": "VND",
        "title": "Vietnamese Dồng"
    }, {
        "value": "VUV",
        "title": "Vanuatu Vatu"
    }, {
        "value": "XAF",
        "title": "CFA Franc BEAC"
    }, {
        "value": "XOF",
        "title": "CFA Franc BCEAO"
    }, {
        "value": "XPF",
        "title": "CFP Franc (Franc Pacifique)"
    }, {
        "value": "ZAR",
        "title": "South African Rand"
    }, {
        "value": "ZWV",
        "title": "Zambian Kwacha"
    }];
}