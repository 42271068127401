import React from 'react';

type TabsProps = {
	value: number;

	children?: React.ReactNode;
	onChange?: (event: React.SyntheticEvent, value: number, slug: string) => void;
};

const Tabs: React.FC<TabsProps> = ({
	value,
	children: childrenProp,
	onChange,
}: TabsProps) => {
	let childIndex = 0;

	const children = React.Children.map(childrenProp, (child) => {
		if (!React.isValidElement(child)) {
			return null;
		}

		const childValue =
			child.props.value === undefined ? childIndex : child.props.value;
		const isCurrent = childValue === value;

		childIndex += 1;
		return React.cloneElement(child, {
			isCurrent,
			onChange,
			value: childValue,
		});
	});

	return (
		<div className={'tabs'}>
			{children}
		</div>
	);
};

export default Tabs;
